import React from 'react'
import { Link } from 'gatsby'
import logo_sombreado from '../images/logo_sombreado.webp';

const ContactBanner = () => {
  return (
    <section className="bg-secondary py-10 md:py-16">
        <div className="md:flex w-11/12 mx-auto md:items-end">
            <div className="md:w-7/12">
            <p className="text-primary text-center md:text-start mb-4">CONTACTO</p>

            
                <h3 className="font-bold text-white text-3xl md:text-5xl">Nos encantaría trabajar contigo </h3>
                <p className="text-gray-200 text-lg md:text-xl md:w-2/3 mt-5">¿Tienes una idea y necesitas una página o aplicación web? No dudes en contactarnos</p>
                <Link to="/contacto" className="bg-primary text-secondary rounded-3xl py-2 px-5 block w-fit mt-5 transition-colors mx-auto md:mx-0 hover:bg-btn1-hover">Contactar</Link>

            </div>
            <div id="logo_sombreado2" className="md:w-5/12 mt-5 animation_2">
            <img src={logo_sombreado} alt="Digital System DA" />
            {/* <StaticImage
                src="../images/logo_sombreado.svg"
                alt="Digital System DA"
                placeholder="blurred"
                quality={100}
            /> */}
            
            {/* <img id="logo_sombreado2" className="animation_2" src={logo_sombreado} alt="Digital System DA" /> */}
            </div>
        </div>
    </section>
  )
}

export default ContactBanner