import React, { useEffect } from "react"
import {Link} from 'gatsby';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";

//Import imagenes WEBP
import checkImg from '../images/check.webp';
import paginas_corporativas from '../images/paginas_corporativas.webp';
import blogs from '../images/blogs.webp';
import app_complejas from '../images/app_complejas.webp';
import logos from '../images/logos.webp';
import responsive from '../images/responsive.webp';
import seo from '../images/seo.webp';
import velocidad from '../images/velocidad.webp';
import proceso from '../images/procesoIcon.webp';
import hero_bg from '../images/bg_hero.webp';
import rocket_bg from '../images/rocket_bg.webp'
import rocket from '../images/rocket.webp';

//Components
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ContactBanner from "../components/ContactBanner";

export default function Home() {
 

  useEffect(() => {

    
    gsap.registerPlugin(ScrollTrigger);

    gsap.to("#bg_hero", {
      scrollTrigger: {
        trigger: "#hero",
        scrub:1,
        
        start: "top top"
      }, 
      scale: 1.1
    })
    
    const nodosAnimar = document.querySelectorAll(".animation_1");

    nodosAnimar.forEach(nodo => {
      
      gsap.to(`#${nodo.id.toString()}`, {
        scrollTrigger: {
          trigger: `#${nodo.id.toString()}`,
          start: "-=400 top",
        }, 
        opacity:1,
        duration: 1
      })
    })

    gsap.to("#rocket", {
      scrollTrigger: {
        trigger: "#rocket",
        scrub: 1,
        start: "-=600 top"
      },
      yPercent: "-300"
    })

    const nodosAnimar2 = document.querySelectorAll(".animation_2");

    nodosAnimar2.forEach(nodo => {

      gsap.to(`#${nodo.id.toString()}`, {
        scrollTrigger: {
          trigger: `#${nodo.id.toString()}`,
          scrub: 1,
          start: "-=500 top"
        },
        scale: 1.15
      })
    })
    
      
  },[])

  const scrolleador = objeto => {

      const donde = document.querySelector(`#${objeto}`)
      window.scroll({
          
          top: Number(donde.offsetTop) - 120,
          behavior: "smooth",
          
      })
      
  }

  const handleCaracteristicas = id => {

    const contenedor = document.querySelector(`#caracteristica${id}`);
    contenedor.classList.toggle("efectoCaracteristica");
  }
  

  return (
    <Layout scrolleador={scrolleador}>
      <div className="overflow-hidden">
        <Seo />
        
        <div id="hero" className="relative h-screen overflow-hidden">
          

          <div id="bg_hero" className="absolute opacity-50 md:opacity-100 -top-1 right-0">
            <img className="h-screen" src={hero_bg} alt="Bienvenido a Digital System DA"/>
            
          </div>
          <div className="absolute z-10 h-screen flex items-center w-11/12 left-0 right-0 mx-auto">
            <div className="w-full md:w-8/12">
              <h1 className="font-bold text-center md:text-start text-3xl md:text-5xl">Contrata profesionales.<span className="block">Obtene resultados profesionales.</span><span className="block mt-10 text-tiny-text text-lg md:text-xl font-normal">Equipo especializado en el diseño y desarrollo de software web</span></h1>
              <div className="flex justify-evenly md:justify-start items-center mt-6">
                
                <Link to="/contacto" className="bg-primary text-secondary rounded-3xl py-2 px-5 block transition-colors hover:bg-btn1-hover">Contactar</Link>
                <Link to="/trabajos" className="bg-gray-200 text-secondary rounded-3xl py-2 px-5 block transition-colors ml-6 hover:bg-gray-300">Ver Trabajos</Link> 
                
              </div>
            </div>
          </div>
          
        </div>

        <main>
          <section id="quienes_somos" className="w-11/12 mx-auto py-20 md:py-40">
            <h2 id="obj1" className="animation_1 opacity-0 md:w-1/2 mx-auto font-bold text-3xl md:text-5xl text-center pb-32 md:pb-36">Somos un equipo que sabe que la tegnología llegó para mejorar el mundo</h2>

            <div  id="obj2" className="animation_1 opacity-0 md:flex md:justify-between md:items-center mt-24"> 
              <div className="md:w-1/2">
                <h3 className="font-bold text-3xl md:text-5xl">Entendemos de software web y queremos ayudar a mejorar tu negocio</h3>
                <p className="block mt-9 text-tiny-text text-lg md:text-xl">Con nuestro equipo buscamos agregar valor a nuestros clientes solucionando sus problemas y  brindando software web de primera mano.</p>
              </div>
              <div className="md:w-1/2 mt-10 md:mt-0">
                <div className="w-11/12 md:w-10/12 mx-auto">
                  <StaticImage
                    src="../images/presentacion1.png"
                    alt="Digital System DA"
                    placeholder="blurred"
                    quality={100}
                  />
                </div>
                
                {/* <img className="w-11/12 md:w-10/12 mx-auto" src={presentacion1} alt="Decoración" /> */}
              </div>
            </div>

            <div  id="obj3" className="animation_1 opacity-0 md:flex md:justify-between md:items-center md:flex-row-reverse mt-24"> 
              <div className="md:w-1/2">
                <h3 className="font-bold text-3xl md:text-5xl">Un equipo nuevo y moderno</h3>
                <p className="block mt-9 text-tiny-text text-lg md:text-xl">Buscamos ser de los mejores en el sector, y vamos a lograrlo con amor, pasión y mucha dedicación</p>
              </div>
              <div className="md:w-1/2 mt-10 md:mt-0">
                <div className="relative">
                  
                  <img className="w-11/12 md:w-10/12 mx-auto" src={rocket_bg} alt="Cohete fondo"/>
                  
                  <div id="rocket" className="absolute left-0 right-0">
                    <img className="block mx-auto w-2/12" src={rocket} alt="Cohete"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="que_hacemos" className="bg-gray-100 py-20">

            <div className="w-11/12 mx-auto">
              <p className="text-primary text-center md:text-start mb-4">QUE HACEMOS</p>

              <div className="md:flex md:items-start">
                <h3 className="font-bold text-3xl md:text-5xl md:w-1/2">Trabajando codo a codo con nuestros clientes buscamos agregar valor a sus empresas</h3>
                <p className="text-tiny-text mt-5 md:mt-0 md:w-1/2 text-lg md:text-xl">Utilizando tegnologías y metodologías actuales buscamos modernizar y automatizar el trabajo de nuestros socios.</p>
              
              </div>

              <div className="grid grid-cols-1 gap-8 md:grid-cols-3 mt-20">

                <div onClick={() => handleCaracteristicas(0)} className="bg-white rounded-xl py-14 relative overflow-hidden">
                  <img className="block mx-auto mb-10 w-10" src={checkImg} alt="Servicio que ofrecemos" />
                  <h2 className="text-secondary text-2xl font-bold text-center mb-7">Páginas corporativas</h2>
                  <p className="w-11/12 mx-auto text-center text-tiny-text">Hay una nueva generación de futuros clientes potenciales que viven conectados dia a dia. Si quieres que tu empresa llegue a estos nuevos clientes, necesitarás estar en Internet</p>
                  <div id="caracteristica0" className="w-full h-full absolute left-full top-0 flex justify-center items-center bg-secondary">
                    <div className="w-9/12">
                        <img src={paginas_corporativas} alt="Paginas web corporativas"/>
                      
                    </div>
                  </div>
                </div>

                <div onClick={() => handleCaracteristicas(1)} className="bg-white relative overflow-hidden rounded-xl py-14">
                  <img className="block mx-auto mb-10 w-10" src={checkImg} alt="Servicio que ofrecemos" />
                  <h2 className="text-secondary text-2xl font-bold text-center mb-7">Aplicaciones web complejas</h2>
                  <p className="w-11/12 mx-auto text-center text-tiny-text">¿Tienes una idea de negocio que quieres llevar a cabo? Aunque tu idea de emprendimiento no tenga nada que ver con la tegnología, esta podría convertirse en tu mejor aliado</p>
                  <div id="caracteristica1" className="w-full h-full absolute left-full top-0 flex justify-center items-center bg-secondary">
                    <div className="w-9/12">
                    <img src={app_complejas} alt="Aplicaciones web complejas"/>

                     
                    </div>
                  </div>
                </div>

                <div onClick={() => handleCaracteristicas(2)} className="bg-white rounded-xl relative overflow-hidden py-14">
                  <img className="block mx-auto mb-10 w-10" src={checkImg} alt="Servicio que ofrecemos" />
                  <h2 className="text-secondary text-2xl font-bold text-center mb-7">Blogs</h2>
                  <p className="w-11/12 mx-auto text-center text-tiny-text">Un blog es el lugar donde brilla la personalidad de tu marca. Allí es donde puedes hablar con tu propia voz y comunicarte directamente con tu público</p>
                  <div id="caracteristica2" className="w-full h-full absolute left-full top-0 flex justify-center items-center bg-secondary">
                    <div className="w-9/12">
                      <img src={blogs} alt="blogs"/>
                      
                    </div>
                  </div>
                </div>

                <div onClick={() => handleCaracteristicas(3)} className="bg-white rounded-xl relative overflow-hidden py-14">
                  
                  <img className="block mx-auto mb-10 w-10" src={checkImg} alt="Servicio que ofrecemos" />
                  <h2 className="text-secondary text-2xl font-bold text-center mb-7">Logos</h2>
                  <p className="w-11/12 mx-auto text-center text-tiny-text">¿Estas empezando un emprendimiento y necesitas un nuevo logotipo? ¿O ya tienes el logotipo y necesitas un diseñador? Nosotros somos la solución</p>
                  <div id="caracteristica3" className="w-full h-full absolute left-full top-0 flex justify-center items-center bg-secondary">
                    <div className="w-9/12">
                      <img src={logos} alt="Logotipos"/>

                      {/* <StaticImage
                        src="../images/logos.svg"
                        alt="Logotipos"
                        quality={100}
                        placeholder="blurred"
                      /> */}
                    </div>
                  </div>
                </div>
                
              </div>

            
            </div>

          </section>

          <ContactBanner />

          <section id="escalabilidad" className="py-20 bg-gray-100">
            <div className="mx-auto w-11/12">
              <p className="text-primary mb-4 text-center md:text-start">CARACTERÍSTICAS</p>

              <div className="md:flex md:items-start">
                <h3 className="font-bold text-secondary text-3xl md:text-5xl md:w-1/2">Software adaptable y personalizable </h3>
                <p className="text-tiny-text mt-5 md:mt-0 md:w-1/2 text-lg md:text-xl">Los sitios web actuales son la carta de presentación de las empresas y nosotros buscamos las mejores características para que la presentación sea una experiencia agradable</p>

              </div>

              <div className="grid grid-cols-1 gap-8 md:grid-cols-3 mt-20">
                <div className="bg-white rounded-xl py-14 relative overflow-hidden">
                  <img className="block mx-auto mb-10 w-10" src={checkImg} alt="Servicio que ofrecemos" />
                  <h2 className="text-secondary text-2xl font-bold text-center mb-7">Responsive</h2>
                 
                  <img className="my-10 block mx-auto h-36" src={responsive} alt="Tegnologia reponsive" />
                  <p className="w-11/12 mx-auto text-tiny-text">Nuestras aplicaciones están adaptadas para funcionar en diferentes dispositivos, sin perder ninguna de sus funcionalidades</p>
                                          
                </div>

                <div className="bg-white rounded-xl py-14 relative overflow-hidden">
                  <img className="block mx-auto mb-10 w-10" src={checkImg} alt="Servicio que ofrecemos" />
                  <h2 className="text-secondary text-2xl font-bold text-center mb-7">SEO</h2>
                 
                    <img className="my-10 block mx-auto h-36" src={seo} alt="Tegnologia reponsive" />
                    
                    <p className="w-11/12 mx-auto text-tiny-text">Utilizamos estrategias SEO para mejorar la aparición de tu sitio web en los resultados de búsquedas orgánicas, y así mejorar tu negocio</p>
                </div>

                <div className="bg-white rounded-xl py-14 relative overflow-hidden">
                  <img className="block mx-auto mb-10 w-10" src={checkImg} alt="Servicio que ofrecemos" />
                  <h2 className="text-secondary text-2xl font-bold text-center mb-7">Responsive</h2>
                 
                    <img className="my-10 block mx-auto h-36" src={velocidad} alt="Tegnologia reponsive" />
                    
                  <p className="w-11/12 mx-auto text-tiny-text">Gracias a las tegnologías y metodologías de trabajo modernas que utilizamos, nuestros sitios web son veloces y óptimos</p>
                </div>
              </div>

              
              <h3 className="font-bold text-secondary mt-40 text-3xl md:text-5xl md:w-1/2 mx-auto text-center">No nos juzgues por lo que decimos, juzganos por lo que hacemos</h3>
              <Link to="/trabajos" className="bg-primary text-secondary rounded-3xl py-2 px-5 mt-20 block w-fit mx-auto transition-colors hover:bg-btn1-hover">Ver trabajos</Link> 
      
            </div>
              
          </section>
          <section id="como_trabajamos" className="pt-20 pb-1 ">
            <div className="w-11/12 mx-auto">
              <p className="text-primary mb-4 text-center md:text-start">COMO TRABAJAMOS</p>

              <div className="md:flex md:items-start md:justify-between">
                <h3 className="font-bold text-secondary text-3xl md:text-5xl md:w-5/12">Un proceso de trabajo ágil y versátil</h3>
                <div className="md:w-6/12 mt-5 md:mt-0">

                  <p className="block text-tiny-text text-lg md:text-xl mb-5">Pasos en el desarrollo de proyecto:</p>
                  
                  <button onClick={() => scrolleador("investigacion")} className="text-gray-500 transition-all hover:text-secondary pt-3 flex items-center" >
                    <img className="mr-2" src={proceso} alt="Forma"/>
                    <p>Investigacón</p>
                  </button>
                  <button onClick={() => scrolleador("diseno")} className="text-gray-500 transition-all hover:text-secondary pt-3 flex items-center" >
                    <img className="mr-2" src={proceso} alt="Forma"/>
                    <p>Diseño</p>
                  </button>

                  <button onClick={() => scrolleador("desarrollo")} className="text-gray-500 transition-all hover:text-secondary pt-3 flex items-center" >
                    <img className="mr-2" src={proceso} alt="Forma"/>
                    <p>Desarrollo</p>
                  </button>

                </div>
              </div>

              <div id="investigacion" className="md:flex md:items-center md:justify-between my-10 md:my-32">
                <div className="md:w-5/12">
                  <p className="text-primary mb-4">PASO UNO</p>

                  <h3 className="font-bold text-3xl md:text-5xl">Investigación</h3>
                  <p className="block mt-10 text-tiny-text text-lg md:text-xl">Nos encargamos de investigar las mejores tecnologías y medotodologías para ofrecer el producto mas óptimo a nuestros clientes</p>
                </div>
                <div className="md:w-1/2 mt-9 md:mt-0">
                  <div id="obj5" className="w-10/12 animation_2 block mx-auto md:mx-0 md:ml-auto">
                    <StaticImage
                      src="../images/investigacion.png"
                      alt="Investigación"
                      placeholder="blurred"
                      quality={100}
                    />
                  </div>
                  {/* <img id="obj5" className="w-10/12 animation_2 block mx-auto md:mx-0 md:ml-auto " src={investigacion} alt="Investigación" /> */}

                </div>
              </div>
              <div id="diseno" className="md:flex md:items-center md:justify-between md:flex-row-reverse my-10 md:my-32">
                <div className="md:w-5/12">
                  <p className="text-primary mb-4">PASO DOS</p>

                  <h3 className="font-bold text-3xl md:text-5xl">Diseño</h3>
                  <p className="block mt-10 text-tiny-text text-lg md:text-xl">Diseño de interfaces y experiencias de usuario agradables, rápidas e intuitivas hacen de nuestros servicios uno de los mejores en el mercado</p>
                </div>
                <div className="md:w-1/2 mt-9 md:mt-0">
                  <div id="obj6" className="w-10/12 block animation_2 mx-auto md:mx-0 md:mr-auto">
                    <StaticImage
                      src="../images/diseño.png"
                      alt="Diseño"
                      placeholder="blurred"
                      quality={100}
                    />
                  </div>
                  {/* <img id="obj6" className="w-10/12 block animation_2 mx-auto md:mx-0 md:mr-auto" src={diseño} alt="Diseño" /> */}

                </div>
              </div>
              <div id="desarrollo" className="md:flex md:items-center md:justify-between my-10 md:my-32">
                <div className="md:w-5/12">
                  <p className="text-primary mb-4">PASO TRES</p>

                  <h3 className="font-bold text-3xl md:text-5xl">Desarrollo</h3>
                  <p className="block mt-10 text-tiny-text text-lg md:text-xl">Tecnologias modernas y metodologías de trabajo ágiles hacen que nuestros clientes esten siempre al tanto de el desarrollo del proyecto</p>
                </div>
                <div className="md:w-1/2 mt-9 md:mt-0">
                  <div id="obj7" className="w-11/12 animation_2 block mx-auto md:mx-0 md:ml-auto">
                    <StaticImage
                      src="../images/desarrollo.png"
                      alt="Desarrollo"
                      placeholder="blurred"
                      quality={100}
                    />
                  </div>
                  {/* <img id="obj7" className="w-11/12 animation_2 block mx-auto md:mx-0 md:ml-auto" src={desarrollo} alt="Desarrollo" /> */}

                </div>
              </div>
            </div>
          </section>
        </main>
     

      </div>
    </Layout>
    

  ) 
}
